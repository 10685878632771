import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["tab", "panel"]

    initialize() {
      this.showTab()
      console.log('Tab is here!')
      this.appType()
    }

    change(event) {
      this.index = this.tabTargets.indexOf(event.currentTarget)
    }

    showTab() {
      this.tabTargets.forEach((tab, index) => {
        const panel = this.panelTargets[index]
        tab.classList.toggle("is-active", index === this.index)
        panel.classList.toggle("is-hidden", index !== this.index)
      })
    }

    get index() {
      return parseInt(this.data.get("index") || 0)
    }

    set index(value) {
      this.data.set("index", value)
      this.showTab()
    }

    appType() {
      const appDocker = document.getElementById('app_is_type_docker')
      const appStatic = document.getElementById('app_is_type_static')
      const appCustomAngularStatic = document.getElementById('app_is_type_static_custom_angular_build')
      const appDockerPanel = document.getElementById('app_is_type_docker_group')
      const appStaticPanel = document.getElementById('app_is_type_static_group')
      const appCustomAngularStaticPanel = document.getElementById('app_is_type_static_custom_angular_build_group')
      const appTypeTabControl = document.getElementById('app-types-radio-select')

      if (appDocker.checked) {
        appTypeTabControl.tabIndex = 2
        appDockerPanel.classList.remove('is-hidden')
      } else if (appStatic.checked) {
        appTypeTabControl.tabIndex = 0
        appStaticPanel.classList.remove('is-hidden')
      } else if (appCustomAngularStatic.checked) {
        appTypeTabControl.tabIndex = 1
        appCustomAngularStaticPanel.classList.remove('is-hidden')
      }
    }
}