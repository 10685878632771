import { Controller } from "stimulus"
import Convert from 'ansi-to-html';

export default class extends Controller {
  static targets = [ "colours" ]

  connect() {
    console.log('Colours connected!')
    this.useAnsiColorCodes()
  }

  useAnsiColorCodes() {
    const convert = new Convert();
    const elementToColorize = this.coloursTarget
    elementToColorize.innerHTML = convert.toHtml(elementToColorize.innerHTML)
  }
}
