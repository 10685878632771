import { Controller } from "stimulus";

function supportsIntersectionObserver() {
  return (
    "IntersectionObserver" in window ||
    "IntersectionObserverEntry" in window ||
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  );
}

export default class extends Controller {
  static targets = [
    "scrollArea",
    "column",
    "columnVisibilityIndicator",
  ];
  static classes = [
    "indicatorVisible",
  ];

  connect() {
    this.startObservingColumnVisibility();
  }

  disconnect() {
    this.stopObservingColumnVisibility();
  }

  startObservingColumnVisibility() {
    if (!supportsIntersectionObserver()) {
      console.warn(`This browser doesn't support IntersectionObserver`);
      return;
    }

    this.intersectionObserver = new IntersectionObserver(
      this.updateScrollNavigation.bind(this),
      {
        root: this.scrollAreaTarget,
        threshold: 0.99, // otherwise, the right-most column sometimes won't be considered visible in some browsers, rounding errors, etc.
      }
    );

    this.columnTargets.forEach((headingEl) => {
      this.intersectionObserver.observe(headingEl);
    });
  }

  stopObservingColumnVisibility() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
  }

  updateScrollNavigation(observerRecords) {
    observerRecords.forEach((record) => {
      record.target.dataset.isVisible = record.isIntersecting;
    });

    this.toggleScrollNavigationVisibility();
    this.updateColumnVisibilityIndicators();
  }

  toggleScrollNavigationVisibility() {
    const allColumnsVisible =
      this.columnTargets.length > 0 &&
      this.columnTargets[0].dataset.isVisible === "true" &&
      this.columnTargets[this.columnTargets.length - 1].dataset.isVisible ===
        "true";
  }

  updateColumnVisibilityIndicators() {
    this.columnTargets.forEach((headingEl, index) => {
      const indicator = this.columnVisibilityIndicatorTargets[index];

      if (indicator) {
        indicator.classList.toggle(
          this.indicatorVisibleClass,
          headingEl.dataset.isVisible === "true"
        );
      }
    });
  }

  scrollLeft() {
    // scroll to make visible the first non-fully-visible column to the left of the scroll area
    let columnToScrollTo = null;
    for (let i = 0; i < this.columnTargets.length; i++) {
      const column = this.columnTargets[i];
      if (columnToScrollTo !== null && column.dataset.isVisible === "true") {
        break;
      }
      if (column.dataset.isVisible === "false") {
        columnToScrollTo = column;
      }
    }

    this.scrollAreaTarget.scroll(columnToScrollTo.offsetLeft, 0);
  }

  scrollRight() {
    // scroll to make visible the first non-fully-visible column to the right of the scroll area
    let columnToScrollTo = null;
    for (let i = this.columnTargets.length - 1; i >= 0; i--) {
      // right to left
      const column = this.columnTargets[i];
      if (columnToScrollTo !== null && column.dataset.isVisible === "true") {
        break;
      }
      if (column.dataset.isVisible === "false") {
        columnToScrollTo = column;
      }
    }

    this.scrollAreaTarget.scroll(columnToScrollTo.offsetLeft, 0);
  }
}
