import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "scroller" ]

  connect() {
    console.log('Scroller connected!')
  }

  scroll() {
    const scrollElem = this.scrollerTarget
    scrollElem.scrollTop = scrollElem.scrollHeight;
    scrollElem.scrollIntoView(false)
  }
}
