import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "showAfterFinished" ]

  connect() {
    console.log('ToggleDuration connected!')
    this.changeVisibilityForDurationField()
  }

  isVisible() {
    let elementBuildDurationClassNames = this.showAfterFinishedTarget.classList
    return elementBuildDurationClassNames.contains("successful") ||
      elementBuildDurationClassNames.contains("failed");
  }

  changeVisibilityForDurationField() {
    this.showAfterFinishedTarget.visibility = this.isVisible() ? 'visible' : 'hidden'
  }
}
