let Rails = require("@rails/ujs")
Rails.start()
let Turbolinks = require("turbolinks")
Turbolinks.start()
require("@rails/activestorage").start()
require("@rails/actioncable")
import "channels"

window.Rails = Rails
import "controllers"

import "bootstrap"
//= require bootstrap-sprockets

require('ansi-to-html')

document.addEventListener('turbolinks:load', () => {
  let acc = document.getElementsByClassName("accordion");
  let i;
  for (i = 0; i < acc.length; i++) {
    acc[i].onclick = function() {
      this.classList.toggle("active");
      this.nextElementSibling.classList.toggle("show");
    }
  }
})

// document.addEventListener('turbolinks:before-cache', () => {
//   application.controllers.forEach(controller => {
//     if (typeof controller.teardown === 'function') {
//       controller.teardown()
//     }
//   })
// })

// document.addEventListener('ajax:before [data-remote]', () => {
//   Turbolinks.clearCache();
// });

