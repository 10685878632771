import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "statusString" ]

  connect() {
    console.log('BuildStatus connected!')
    this.changeTextToSynonym()
  }

  synonymToClass() {
    let elementBuildResultStatusClassNames = this.statusStringTarget.classList
    if (elementBuildResultStatusClassNames.contains("successful")) {
      return "successful"
    } else if
    (elementBuildResultStatusClassNames.contains("failed")) {
      return "failed"
    } else { return "building" }
  }

  changeTextToSynonym() {
    this.statusStringTarget.textContent = this.synonymToClass()
  }
}
